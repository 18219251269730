import VitaElement from "./vita_element";
import Img02 from '../../res/02k.jpg'
import Img08 from '../../res/08k.jpg'
import Img12 from '../../res/12.jpg'
import Img13 from '../../res/13.jpg'
import Img15 from '../../res/15.jpg'


export default function Vita() {
    return(
        <section className="min-h-screen w-screen overflow-hidden p-4 max-w-screen-2xl pb-24">
            <VitaElement pic={Img02} headline="Meine Karriere" direction1="flex-col lg:flex-row" direction2="lg:justify-end" direction3="lg:items-start lg:pr-32" direction4="lg:px-0 lg:pl-16 lg:w-full" text="Ich startete meine tänzerische Laufbahn im Alter von 10 Jahren und trainiere seitdem an sämtlichen Tanzstilen, um ein möglichst großes Know-How zu erlangen. Ich nahm zudem am Advanced Dance Program (by Sonia Bartucelli) und bei Make it Count (by Denis Weckbach) in Berlin teil und bilde mich stetig auf Workshops internationaler Dozenten w.z.B. Brian Friedman (Michael Jackson, Britney Spears) weiter. "/>
            <VitaElement pic={Img15} headline="Meine Arbeit" direction1="flex-col lg:flex-row-reverse" direction2="lg:justify-start" direction3="lg:items-end lg:pl-32" direction4="lg:px-0 lg:pr-16 lg:w-full" text="Ich arbeite national und international als Tänzer, Tanzlehrer (Erfurt, Leipzig usw.) und Choreograph (z.B. für die 30 Jahre Solo Tour von Anita und Alexandra Hofmann, Wettkampfgruppen oder im Theater Weimar). "/>
            <VitaElement pic={Img08} headline="Meine Auftritte" direction1="flex-col lg:flex-row" direction2="lg:justify-end" direction3="lg:items-start lg:pr-32" direction4="lg:px-0 lg:pl-16 lg:w-full" text="Ich tanzte in der Rocky Horror Picture Show (Alte Oper Erfurt) für Künstler wie Kery Fay und Blümchen (Jasmin Wagner) und stehe in Fernsehshows, Werbespots und Musikvideos als Tänzer vor der Kamera (Let´s Dance (Tänzer), Kaufland-Werbespot (Tänzer), Aron Pinter – Boy,Boy,Boy Live (Tänzer und Choreograf), Marie Reim – Bist du dafür bereit (Tänzer). "/>
            <VitaElement pic={Img12} headline="Meine Erfolge" direction1="flex-col lg:flex-row-reverse" direction2="lg:justify-start" direction3="lg:items-end lg:pl-32" direction4="lg:px-0 lg:pr-16 lg:w-full" text="Ich bin ostdeutscher Meister (DTHO Hip-Hop - Solo), Vize-Europameister (UDO – Group Evolution 7), und Weltmeister (UDO – Group Evolution 7) im Bereich Hip-Hop Streetdance. "/>
            <VitaElement pic={Img13} headline="Meine Kurse" direction1="flex-col lg:flex-row" direction2="lg:justify-end" direction3="lg:items-start lg:pr-32" direction4="lg:px-0 lg:pl-16 lg:w-full" text="Ich glaube, dass ich durch meine Bereitschaft Neues auszuprobieren und zu lernen ein sehr vielseitiger Tanzlehrer bin. In meinen Kursen, kombiniere ich die verschiedensten Tanzrichtungen zu einer ganz eigenen Mischung. Ein besonderes Augenmerk meines Unterrichtes liegt auf dem Ausdruck eines Tänzers und einer Choreografie. Dies kann sehr unterschiedlich ausfallen - von emotional bis selbstbewusst. Ich unterrichte hauptsächlich im Bereich Commercial Dance in Leipzig."/>
        </section>

    )
}