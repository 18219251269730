import Datenschutz from "./datenschutz";


export default function DatenRoute() {
    return (
        <section className="router-body">
            <Datenschutz/>
        </section>
    );
}
