

export default function VitaElement(props) {
    return(
        <div className={`flex ${props.direction1} justify-center items-center mt-16`}>
            <div className={`flex justify-center lg:w-2/5 ${props.direction2}`}>
                <img src={props.pic} alt="vita_pic" className="border-2 border-black rounded-md max-h-96 max-w-fit"/>
            </div>
            <div className={`flex flex-col py-4 lg:w-3/5 ${props.direction3}`}>
                <h2 className={`text-center text-xl font-bold sm:w-128  ${props.direction4}`}>{props.headline}</h2>
                <p className={`px-8 text-justify text-lg font-semibold sm:w-128 ${props.direction4} ${props.sch}`}>{props.text}</p>
            </div>
        </div>
    )
}