import './App.css'
import BookingRoute from './components/booking/booking_route'
import Footer from './components/common/footer'
import Header from './components/common/header'
import ImpressumRoute from './components/common/impressum/impressum_route'
import DatenRoute from './components/common/datenschutz/datenschutz_route'
import AGBsRoute from './components/common/agb/agb_route'
import GalleryRoute from './components/gallery/gallery_route'
import HomepageRoute from './components/homepage/home_route'
import VitaRoute from './components/vita/vita_route'
import LoginRoute from './components/admin/login/login_route'
import InterfaceRoute from './components/admin/interface/interface_route'
import InterfaceDetailRoute from './components/admin/interface_detail/interfaceDetail_route'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Modal } from 'react-daisyui'
import { useEffect, useState } from 'react'
import CheckoutRoute from './components/payment/paypment_route'

function App() {
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        setVisible(!localStorage.getItem('accepted'))
    }, [])

    const handleClick = () => {
        localStorage.setItem('accepted', true)
        setVisible(!visible)
    }

    return (
        <main className={'flex flex-col min-h-screen overflow-auto'}>
            <Header />
            <Modal
                open={visible}
                className="text-base-200 rounded-sm flex flex-col items-center">
                <Modal.Header className="font-bold mb-2 text-center">
                    Diese Website benutzt Cookies!
                </Modal.Header>

                <Modal.Body className="text-justify">
                    Um das Nutzererlebnis zu steigern und alle Prozesse der
                    Website reibungslos ablaufen zu lassen verwenden wir
                    Cookies.
                </Modal.Body>

                <Modal.Actions>
                    <button
                        onClick={handleClick}
                        className="btn btn-success font-bold text-white">
                        Einverstanden
                    </button>
                </Modal.Actions>
            </Modal>
            <div className="flex-grow text-center text-white font-new bg-base-200 contents">
                <Routes>
                    <Route path="*" element={<Navigate to={'/'} />} />
                    <Route exact path="/" element={<HomepageRoute />} />
                    <Route excat path="/vita" element={<VitaRoute />} />
                    <Route exact path="/galerie" element={<GalleryRoute />} />
                    <Route exact path="/kurse" element={<BookingRoute />} />
                    <Route
                        exact
                        path="/kurse/:error"
                        element={<BookingRoute />}
                    />
                    <Route
                        exact
                        path="/impressum"
                        element={<ImpressumRoute />}
                    />
                    <Route exact path="/datenschutz" element={<DatenRoute />} />
                    <Route exact path="/agbs" element={<AGBsRoute />} />
                    <Route
                        exact
                        path="/basti-in-control"
                        element={<LoginRoute />}
                    />
                    <Route
                        exact
                        path="/interface"
                        element={<InterfaceRoute />}
                    />
                    <Route
                        exact
                        path="/interface/:event_id"
                        element={<InterfaceDetailRoute />}
                    />
                    <Route exact path="/checkout" element={<CheckoutRoute />} />
                </Routes>
            </div>
            <Footer />
        </main>
    )
}

const BASE =
    process.env.REACT_APP_BACKEND_URL ?? 'https://api.sebastianbrendel.dance'
export { BASE, App }
