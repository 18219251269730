import HeadlineWidget from '../common/headline'
import Booking from './booking'
import {useParams} from "react-router-dom";
import {createNotificationContentFromCode, Notification} from "../common/util/notification";
import {useState} from "react";

export default function BookingRoute() {
    const {error} = useParams()
    const notification = createNotificationContentFromCode(error)
    const [showNotification, setShowNotification] = useState(error !== undefined)
    const closeNotification = () => {
        setShowNotification(false)
    }
    return (
        showNotification ?
            <section className="router-body">
                <Notification visible={showNotification} error={notification}
                              close={closeNotification}/>
                <HeadlineWidget text="Kurse"/>
                <Booking/>
            </section> : <section className="router-body">
                <HeadlineWidget text="Kurse"/>
                <Booking/>
            </section>
    )
}
