
export default function GridPicV(props) {
    const bgstyle = {backgroundImage: `url(${props.bgimg})`, backgroundSize: 'cover'};
    return(
        <div className={`flex justify-center ${props.classes}`}>
            <div className={`flex justify-center w-80 h-112`} >
                <div className="w-full h-auto" style={bgstyle}>
                </div>
            </div>
        </div>
    )
}