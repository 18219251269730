export default function TextInputField(props) {
    return (
        <div className={`form-control ${props.margin}`}>
            <label className="label pb-0">
                <span className="label-text text-lg italic font-semibold text-primary">{props.text}</span>
            </label>
            <input id={props.id ?? ""} name={props.id} type={props.type ?? "text"} placeholder={props.placeholder}
                   disabled={props.disabled}
                   className={`input input-bordered border-secondary bg-base-200 w-full disabled:border-secondary disabled:bg-base-200" +
                                          " disabled:opacity-50 ${props.width} ${props.disabled ? "input-disabled" : ""}`}/>
        </div>
    )
}
