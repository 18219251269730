import AGB from "./agbs";


export default function AGBsRoute() {
    return (
        <section className={"router-body"}>
            <AGB/>
        </section>
    )
}
