import { BASE } from '../App'
import { getHeader } from './login.service'
import { handleReservationError } from './error.handler'


export async function generateToken(navigate) {
    const location = '/booking/token'
    return fetch(BASE + location, {
        headers: getHeader(),
        method: 'GET',
    })
        .then((response) => response.json())
        .catch((err) => handleReservationError(err, navigate))
}


export async function getOrderId(navigate) {
    const location = '/booking/order'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: 'get',
    }).then((res) => res.json())
        .then((data) => handleReservationError(data, navigate))
        .catch((e) => handleReservationError(e, navigate))
}

export async function capturePayment(order_id) {
    const location = '/booking/capture/' + order_id
    await fetch(BASE + location, {
        headers: getHeader(),
        method: 'post',
    }).catch((e) => console.error(e))

}
