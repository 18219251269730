
export default function GridPicH(props) {
    const bgstyle = {backgroundImage: `url(${props.bgimg})`, backgroundSize: 'cover'};
    return(
        <div className={`flex justify-center ${props.classes}`}>
            <div className={`flex justify-center w-80 h-48 lg:w-120 lg:h-80`} >
                <div className="w-full h-auto" style={bgstyle}>
                </div>
            </div>
        </div>
    )
}