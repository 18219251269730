import {useNavigate} from 'react-router-dom'
import {createNewEvent} from '../../../services/event.service'
import {prettifyDateString} from '../../../utils/dates'
import {currencyFormatter} from '../../../utils/currency'


export default function InterfaceTableRow({event, openDeleteConfirmationModal, updateAllEvents}) {
    let navigate = useNavigate()
    const teilnehmerString = () => {

        if (!event._id)
            return '-'
        return `${event.bookings.length}/${event.availiable_slots}`

    }
    const preisString = () => {
        if (!event._id)
            return '-'
        return `${currencyFormatter.format(event.brutto_profit)}/${currencyFormatter.format(event.netto_profit)}`
    }

    async function freischalten() {
        const newEventList = await createNewEvent(event, navigate)
        updateAllEvents(newEventList)
    }

    function getLastActionButton() {
        if (!event._id) {
            return <button className={`btn btn-outline w-24 sm:w-32 btn-success glass`}
                           onClick={freischalten}>Freischalten</button>
        } else {
            if (event.disabled) {
                return <button className={`btn  w-24 sm:w-32 btn-error text-error btn-disabled `}>Abgesagt</button>
            } else {
                return <button className={`btn btn-outline w-24 sm:w-32 btn-error`}
                               onClick={() => openDeleteConfirmationModal(event)}>Absagen</button>
            }
        }
    }

    function getInfoButton() {
        return event._id ?
            <button
                className={`btn btn-info w-min btn-outline sm:w-32`}
                onClick={() => navigate(`/interface/${event._id}`)}>Info
            </button>
            :

            <button
                className={'btn btn-info w-min btn-disabled opacity-40 sm:w-32'}> Info
            </button>

    }

    return (
        <tr className={event.disabled ? "text-secondary/50" : ""}>
            <th className='text-center'>{event.calendar_week}</th>
            <td className='text-center'>{prettifyDateString(event.date)}</td>
            <td className='text-center hidden sm:table-cell'>{teilnehmerString()}</td>
            <td className='text-center hidden lg:table-cell'>{preisString()}</td>
            <td className='text-center'>{getInfoButton()}</td>
            <td className='text-center'>{getLastActionButton()}</td>
        </tr>
    )
}
