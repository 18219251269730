import Img17 from '../../res/17.jpg'

export default function HeadlineWidget(props) {
    const bgstyle = {
        backgroundImage: `url(${Img17})`,
        backgroundSize: 'cover',
    }

    return (
        <section
            className="w-screen flex justify-center items-center lg:h-56"
            style={bgstyle}>
            <h1 className="text-5xl text-base-100 font-bold py-4 sm:text-7xl lg:8xl xl:text-9xl">
                {props.text}
            </h1>
        </section>
    )
}
