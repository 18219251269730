import HeadlineWidget from '../../common/headline'
import InterfaceDetail from './interfaceDetail'
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getEventById} from "../../../services/event.service";
import {prettifyDateWithoutTimeString} from "../../../utils/dates";


export default function InterfaceDetailRoute() {
    const navigate = useNavigate()
    const location = useLocation()
    const [event, setEvent] = useState()
    useEffect(() => {
        const id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        getEventById(id, navigate).then((res) => {
            switch (res.code) {
                case 200:
                    setEvent(res.event)
                    break
                case 500:
                    alert('Ein Fehler beim Laden des Events ist aufgetreten!')
                    break
                default:
                    navigate("/")
                    break
            }
        })
    }, [navigate])
    return (
        <section className="router-body">
            <HeadlineWidget text={prettifyDateWithoutTimeString(event?.date)}/>
            <InterfaceDetail event={event}/>
        </section>
    )
}
