import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InterfaceTableRow from './interfaceTableRow'
import { getAuthState, logout } from '../../../services/login.service'
import { createCustomEvent, generateOTP, loadAllEvents } from '../../../services/event.service'
import DeleteConfirmationModal from '../../common/admin/deleteConfirmationModal'
import InputDataModal from '../../common/admin/inputDataModal'

export default function Interface() {
    const navigate = useNavigate()
    const [events, setEvents] = useState([])
    const [isModalOpen, setModalOpen] = useState(false)

    const [currentDeleteEvent, setCurrentDeleteEvent] = useState()

    useEffect(() => {
        getAuthState().then((code) => {
            switch (code) {
                case 400:
                case 401:
                case 403:
                    navigate('/login')
                    break
                case 500:
                    alert('Ein Fehler beim Laden der Events ist aufgetreten!')
                    break
                default:
                    loadAllEvents(navigate).then((list) => {
                        setEvents(list)
                    })
            }
        })
    }, [navigate])

    const openDeleteModal = async (e) => {
        await generateOTP(e, navigate)
        setCurrentDeleteEvent(e)
        setModalOpen(true)
    }

    const onEventListChanged = () => {
        loadAllEvents(navigate).then((list) => {
            setEvents(list)
        })
    }

    const closeDeleteModal = () => {
        setCurrentDeleteEvent(undefined)
        setModalOpen(false)
    }

    const [showInputField, setshowInputField] = useState(false)
    const [tdate, settdate] = useState('')
    const [start_time, setstart_time] = useState('')
    const [end_time, setend_time] = useState('')
    const [title, settitle] = useState('')
    const [description, setdescription] = useState('')
    const [location, setlocation] = useState('')
    const [availiable_slots, setavailiable_slots] = useState(20)
    const [price, setprice] = useState(14)

    const getClassInput = () => {
        const inputData = {
            date: convertDate(tdate, parseInt(start_time.substring(0, 2)),  parseInt(start_time.substring(3, 5))),
            start_time: start_time + ':00',
            end_time: end_time + ':00',
            title: title,
            description: description,
            location: location, 
            availiable_slots: parseInt(availiable_slots),
            price: parseInt(price)
        }
        console.log(inputData)
        createCustomEvent(inputData, navigate)
    }

    function convertDate(inputDate, hour, min) {
        const date = new Date(inputDate);
        date.setHours(hour, min, 0, 0);
        const formattedDate = date.toISOString().replace('.000Z', '.000Z');
        return formattedDate;
    }
    return (
        <div className="overflow-x-auto px-0 md:px-8 mb-16 w-full xl:w-2/3">
            <DeleteConfirmationModal
                event={currentDeleteEvent}
                isOpen={isModalOpen}
                close={closeDeleteModal}
                onEventsChanged={onEventListChanged}
            />
            <InputDataModal 
                isOpen={showInputField}
                close_modal={() => setshowInputField(!showInputField)}
                sendData={getClassInput}
                title={title}
                settitle={settitle}
                start_time={start_time}
                setstart_time={setstart_time}
                end_time={end_time}
                setend_time={setend_time}
                tdate={tdate} 
                settdate={settdate}
                description={description}
                setdescription={setdescription}
                location={location}
                setlocation={setlocation}
                availiable_slots={availiable_slots}
                setavailiable_slots={setavailiable_slots}
                price={price}
                setprice={setprice}
            />
            <div className="flex w-full px-2 py-8">
                <button
                    className="btn inline-flex items-center btn-outline btn-secondary "
                    onClick={() => navigate('/kurse')}>
                    <svg
                        viewBox="0 0 1024 1024"
                        fill="gray"
                        height="1em"
                        width="1em"
                        className="mr-1">
                        <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" />
                    </svg>
                    Zu den Events
                </button>
                <button 
                    onClick={() => setshowInputField(!showInputField)}
                    className='btn px-6 ml-auto gap-2'
                >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    Neu
                </button>
                <button
                    className="btn btn-secondary ml-4 gap-2"
                    onClick={() => logout(navigate)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#2c3e50"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                        <path d="M7 12h14l-3 -3m0 6l3 -3" />
                    </svg>
                    Ausloggen
                </button>
            </div>

            <table className="table w-full [&_*]:bg-black/30 table-zebra">
                {/* <!-- head --> */}
                <thead>
                    <tr>
                        <th className="text-center sm:text-lg">KW</th>
                        <th className="text-center sm:text-lg">Datum</th>
                        <th className="text-center sm:text-lg hidden sm:table-cell">
                            Teilnehmer
                        </th>
                        <th className="text-center sm:text-lg hidden lg:table-cell">
                            Einnahmen (B/N)
                        </th>
                        <th className="text-center sm:text-lg">Details</th>
                        <th className="text-center sm:text-lg">Absagen</th>
                    </tr>
                </thead>
                <tbody>
                    {/* <!-- row 1 --> */}
                    {events.map((e) => {
                        return (
                            <InterfaceTableRow
                                key={e.date}
                                event={e}
                                openDeleteConfirmationModal={openDeleteModal}
                                updateAllEvents={setEvents}
                                showPopUp={() => setshowInputField(!showInputField)}
                            />
                        )
                    })}

                    <tr>
                        <td></td>
                        <td></td>
                        <td className="hidden sm:table-cell"></td>
                        <td></td>
                        <td className="hidden lg:table-cell"></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
