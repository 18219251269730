import HeadlineWidget from '../../common/headline'
import Login from './login'
import Loader from '../../common/loading'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { BASE } from '../../../App'
import { getAuthState } from '../../../services/login.service'

export default function LoginRoute() {
    const [authState, setAuthState] = useState(-1)
    const navigate = useNavigate()
    useEffect(() => {
        getAuthState().then((code) => setAuthState(code))
    }, [])

    const LoginWrapper = () => {
        if (authState === -1) return <Loader />
        if (authState === 200) {
            return <Navigate to="/interface" />
        }
        if (authState === 401) return <Login onLogin={loginAndForward} />
        return null
    }

    async function loginAndForward(username, password) {
        const endpoint = '/auth/login'
        
        const res = await fetch(BASE + endpoint, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        })
            .then((r) => r.json())
            .catch((e) => {
                console.log(e)
                return 'Der Login-Service ist aktuell nicht erreichbar. Bitte an den Administrator wenden.'
            })
        switch (res.code) {
            case 200:
                window.localStorage.setItem('bdl_token', res.token)
                window.localStorage.setItem('bdl_expiry', res.exp)
                navigate('/interface')
                break
            case 400:
            case 401:
            case 402:
            case 403:
                return 'Bitte die Zugangsdaten überprüfen.'
            case 500:
            default:
                return 'Der Login-Service ist aktuell nicht erreichbar. Bitte an den Administrator wenden.'
        }
    }

    return (
        <section className="router-body gap-4 md:gap-12">
            <HeadlineWidget text="Login" />
            <LoginWrapper />
        </section>
    )
}
