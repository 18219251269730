import { BASE } from '../App'
import { getHeader } from './login.service'
import { RESERVATION_TOKEN_EXPIRY_NAME, RESERVATION_TOKEN_NAME } from '../utils/constants'
import { handleAuthError, handleReservationError } from './error.handler'

export async function loadAllEvents(navigate, publicSite = false) {
    const location = '/events/list'
    const method = 'GET'
    return await fetch(BASE + location, {
        headers: getHeader(publicSite),
        method: method,
    }).then((res) => res.json())
        .then((data) => {
            handleAuthError(data, navigate)
            return data
        })
        .catch((_) => console.log(_))
}

export async function getEventById(event_id, navigate) {
    const location = '/events/by_id/' + event_id
    const method = 'GET'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: method,
    }).then((res) => res.json())
        .then((data) => {
            handleAuthError(data, navigate)
            return data
        })
        .catch((_) => console.log(_))
}


export async function createNewEvent(event, navigate) {
    const location = '/events/create'
    const method = 'POST'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: method,
        body: JSON.stringify({
            date: event.date,
        }),
    }).then((res) => res.json())
        .then((data) => handleAuthError(data, navigate))
        .catch((_) => console.log(_))
}

export async function createCustomEvent(body, navigate) {
    const location = '/events/custom/create'
    const method = 'POST'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: method,
        body: JSON.stringify(body),
    }).then((res) => res.json())
        .then((data) => handleAuthError(data, navigate))
        .catch((_) => console.log(_))
}

export async function disableEvent({ _id }, otp, navigate) {

    const location = `/events/disable/${_id}/${otp}`
    const method = 'POST'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: method,
    }).then((res) => res.json())
        .then((data) => handleAuthError(data, navigate))
        .catch((_) => console.log(handleAuthError(_, navigate)))
}

export async function loadReservation(navigate) {
    const location = `/events/reservation`
    const method = 'GET'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: method,
    }).then((res) => res.json())
        .then((reservation) => setReservationCookies(reservation))
        .catch((_) => handleReservationError(_, navigate))
}

export async function createReservation(event, customer, navigate) {
    const location = `/events/reservation/${event._id}`
    const method = 'POST'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: method,
        body: JSON.stringify({ customer: customer }),
    }).then((res) => res.json())
        .then((data) => handleReservationError(data, navigate))
        .then((reservation) => setReservationCookies(reservation))
        .catch((_) => handleReservationError(_, navigate))
}

async function setReservationCookies(reservation) {
    if (!reservation?.token) return reservation
    if (!reservation?.expiry) return reservation
    window.localStorage.setItem(RESERVATION_TOKEN_NAME, reservation.token)
    window.localStorage.setItem(RESERVATION_TOKEN_EXPIRY_NAME, reservation.expiry)
    return reservation
}

export function removeReservationCookies() {
    window.localStorage.removeItem(RESERVATION_TOKEN_EXPIRY_NAME)
    window.localStorage.removeItem(RESERVATION_TOKEN_NAME)
}


export async function generateOTP(event, navigate) {
    const location = '/events/otp/' + event._id
    const method = 'POST'
    return await fetch(BASE + location, {
        headers: getHeader(),
        method: method,
    }).then((res) => res.json())
        .then((data) => handleAuthError(data, navigate))
        .catch((_) => console.log(_))
}
