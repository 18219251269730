import {BASE} from '../App'

export async function sendContactMail(formData) {
    return await fetch(BASE + '/contact', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            data: formData,
        }),

    }).then((response) => response.json())
}
