import React, {useEffect, useState} from 'react'
//import PaymentForm from './paypal_input_form'
//import { useParams } from 'react-router-dom'
import {useNavigate} from "react-router-dom";
import CheckoutPage from "./checkout_page";

export default function CheckoutRoute() {
    const navigate = useNavigate()
    const [token, setToken] = useState(undefined)
    const [reservation, setReservation] = useState(undefined)
    const canShowPaymentForm = () => {
        return token !== undefined && reservation !== undefined
    }

    useEffect(() => {
        //generateToken(navigate).then((clientToken) => setToken(clientToken))

        //{canShowPaymentForm() ? <PaymentForm client_token={token}/> : <Loader/>}
    }, [])
    return (
        <section className="router-body">
            <CheckoutPage/>
        </section>
    )

}
