import Event from './event'
import { useEffect, useState } from 'react'
import { loadAllEvents } from '../../services/event.service'
import { useNavigate } from 'react-router-dom'

export default function Booking() {
    const navigate = useNavigate()
    const [events, setEvents] = useState()

    useEffect(() => {
        loadAllEvents(navigate, true).then(events => {
            setEvents(events ?? [])
        })
    }, [navigate])

    const getContent = () => {
        if (events === undefined || events === null) {
            return <h1 className='text-2xl'>Lade...</h1>
        }
        if (events.length === 0) {
            return <h1 className='text-2xl'>Aktuell sind keine Kurse geplant</h1>
        }
        return events?.map((event) => {
            return  <Event key={event.date} event={event} />
        })
    }


    return (
        <section className='min-h-screen w-screen max-w-screen-2xl flex flex-col items-center pt-24'>
            {getContent()}
        </section>
    )
}
