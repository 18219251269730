import React from "react";


const Loader = (props) => {

    return (
        <div className="flex w-full h-full bg-base-200 ">
            <progress className="progress w-56 progress-info m-auto"/>
        </div>
    );
};

export default Loader;
