import React from 'react'
import { Modal } from 'react-daisyui'

export default function InputDataModal({
    isOpen,
    close_modal,
    sendData,
    title,
    settitle,
    start_time,
    setstart_time,
    end_time,
    setend_time,
    tdate, 
    settdate,
    description,
    setdescription,
    location,
    setlocation,
    availiable_slots,
    setavailiable_slots,
    price,
    setprice
}) {

    return (
        <Modal open={isOpen} className="text-base-200 w-full max-w-xl">
            <Modal.Header className="font-bold mb-2">
                Kursdaten eintragen
            </Modal.Header>

            <Modal.Body className="text-justify">
                <form
                    onSubmit={() => {
                        sendData()
                        close_modal()
                    }}
                    className={
                        'flex w-full px-16 mt-4 justify-center flex-col gap-2'
                    }>
                    <label className="pl-2 text-sm">Titel</label>
                    <input
                        className="input input-bordered w-full max-w-5xl text-black text-center"
                        onChange={(e) => settitle(e.target.value)}
                        required
                        value={title}
                    />
                    <label className="pl-2 text-sm">Beschreibung</label>
                    <input
                        className="input input-bordered w-full max-w-5xl text-black text-center"
                        onChange={(e) => setdescription(e.target.value)}
                        required
                        value={description}
                    />
                    <label className="pl-2 text-sm">Wo?</label>
                    <input
                        className="input input-bordered w-full max-w-5xl text-black text-center"
                        onChange={(e) => setlocation(e.target.value)}
                        required
                        value={location}
                    />
                    <label className="pl-2 text-sm">Datum?</label>
                    <input
                        type='date'
                        min={(new Date()).toISOString().substring(0, 10)}
                        className="input input-bordered w-full max-w-5xl text-black text-center"
                        onChange={(e) => settdate(e.target.value)}
                        required
                        value={tdate}
                    />
                    <div className='flex gap-4'>
                        <div className='flex flex-col w-full'>
                            <label className="pl-2 text-sm">Wann Start?</label>
                            <input
                                type='time'
                                className="input input-bordered w-full max-w-5xl text-black text-center"
                                onChange={(e) => setstart_time(e.target.value)}
                                required
                                value={start_time}
                            />
                        </div>
                        <div className='flex flex-col w-full'>
                            <label className="pl-2 text-sm">Wann Ende?</label>
                            <input
                                type='time'
                                className="input input-bordered w-full max-w-5xl text-black text-center"
                                onChange={(e) => setend_time(e.target.value)}
                                required
                                value={end_time}
                            />
                        </div>
                    </div>
                    <label className="pl-2 text-sm">Teilnehmer-Slots</label>
                    <input
                        type='number'
                        className="input input-bordered w-full max-w-5xl text-black text-center"
                        onChange={(e) => setavailiable_slots(e.target.value)}
                        required
                        value={availiable_slots}
                    />
                    <label className="pl-2 text-sm">Preis</label>
                    <input
                        type='number'
                        className="input input-bordered w-full max-w-5xl text-black text-center"
                        onChange={(e) => setprice(e.target.value)}
                        required
                        value={price}
                    />
                    <button
                    type='submit'
                    className={`btn w-48 self-end mt-3`}>
                        Event Anlegen
                    </button>
                </form>
            </Modal.Body>

            <div className="flex flex-row justify-between">
                <Modal.Actions>
                    <button
                        className="flex felx-row items-center text-gray-400"
                        onClick={close_modal}>
                        <svg
                            viewBox="0 0 1024 1024"
                            fill="gray"
                            height="1em"
                            width="1em"
                            className="mr-1">
                            <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" />
                        </svg>
                        Zurück
                    </button>
                </Modal.Actions>

                <Modal.Actions>
                    <button onClick={close_modal} className={`btn btn-error`}>
                        Verwerfen
                    </button>
                </Modal.Actions>
            </div>
        </Modal>
    )
}
