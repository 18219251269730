export async function handleAuthError(fetchResult, navigate) {
    if (fetchResult.code === 400 || fetchResult.code === 401 || fetchResult === 403) {
        console.log("Redirect due to error 4xx")
        navigate('/login')
    } else if (fetchResult.code === 500) {
        console.log("Redirect due to error 500")
        navigate('/')
    }
    return fetchResult
}


export async function handleReservationError(fetchResult, navigate) {
    switch (fetchResult.code) {
        case 200:   // reservation successful - don't forward
            break;
        case 400:   // send request without token
        case 401:   // reservation expired
        case 410: // event fully booked
            navigate("/kurse/" + fetchResult.code)
            break
        case 500:
            navigate("/")
            break
        default:
    }
    return fetchResult
}
