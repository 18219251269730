import {Modal} from "react-daisyui";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {disableEvent} from "../../../services/event.service";
import {prettifyDateWithoutTimeString} from "../../../utils/dates";


export default function DeleteConfirmationModal({isOpen, close, event, onEventsChanged}) {
    const navigate = useNavigate()
    const [otp, setOtp] = useState("")
    const [responseText, setResponseText] = useState("")
    const [responseTextColor, setResponseTextColor] = useState("text-success")
    const [disableCancelButton, setDisableCancelButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const deleteConfirmed = async () => {
        if (otp.trim().length === 0) {
            setResponseText("Bitte gib den Sicherheitscode ein.")
            setResponseTextColor("text-error")
            return
        }

        setLoading(true)
        setDisableCancelButton(true)

        const r = await disableEvent(event, otp, navigate)
        if (r.code === 200) {
            setResponseText(r.DisplayMessage)
            setResponseTextColor("text-success")
            onEventsChanged()
        } else if (r.code === 501 || r.code === 422) {
            setDisableCancelButton(false)
            setResponseText(r.DisplayMessage)
            setResponseTextColor("text-error")
        }

        setLoading(false)

    }

    const close_modal = () => {
        setResponseText("")
        setResponseTextColor("text-success")
        setDisableCancelButton(false)

        close()
    }

    return (
        <Modal open={isOpen} className="text-base-200">
            <Modal.Header className="font-bold mb-2">
                Bist du dir sicher?
            </Modal.Header>

            <Modal.Body className="text-justify">
                <p>
                    {`Wenn du den Kurs vom ${prettifyDateWithoutTimeString(event?.date)} entgültig absagst, kannst du ihn nicht wieder aktivieren. Deine bisher gebuchten Kunden werden über die Absage informiert und bekommen ihr Geld erstattet.`}
                    <br/>
                    Ein 6-stelliger Sicherheitscode wurde an deine Email versandt. Bitte gib ihn hier ein.
                </p>
                <div className={"flex w-full px-24 md:px-32 mt-4 justify-center"}>

                    <input
                        defaultValue={""}
                        type="number"
                        onChange={(text) => setOtp(text.target.value)}
                        placeholder="••••••"
                        className="input input-bordered w-full lg:max-w-lg text-black text-center"
                    />
                </div>
                <div className={`text-center text-md ${responseTextColor}`}>
                    {responseText}
                </div>
            </Modal.Body>

            <div className="flex flex-row justify-between">
                <Modal.Actions>
                    <button className="flex felx-row items-center text-gray-400" onClick={close_modal}>
                        <svg
                            viewBox="0 0 1024 1024"
                            fill="gray"
                            height="1em"
                            width="1em"
                            className="mr-1"
                        >
                            <path
                                d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"/>
                        </svg>
                        Zurück
                    </button>
                </Modal.Actions>

                <Modal.Actions>
                    <button onClick={deleteConfirmed}
                            className={`btn btn-error ${disableCancelButton ? "btn-disabled" : ""} ${loading ? "loading" : ""}`}>{loading ? "" : "Endgültig Absagen"}
                    </button>
                </Modal.Actions>
            </div>
        </Modal>

    )
}
