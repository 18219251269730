import {useEffect, useState} from 'react'
import Img10 from '../../res/10s.jpg'
import {FormatPhoneNumber, ValidateEmail, ValidatePhoneNumber} from '../common/util/validation'
import {createReservation} from '../../services/event.service'
import {useLocation, useNavigate} from 'react-router-dom'

export default function Event({event}) {
    const [errorMsg, setErrorMsg] = useState('')
    const [agbCheck, setAgbCheck] = useState(false)
    const [name, setName] = useState('')
    const [mail, setMail] = useState('')
    const [tel, setTel] = useState('')
    const [gebDate, setGebDate] = useState('')
    const [loadingStyleTag, setLoadingStyleTag] = useState('')
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (getCustomer() === undefined) return
        setName(getCustomer().name)
        setMail(getCustomer().email)
        setTel(getCustomer().telephone)
        setGebDate(getCustomer().birthday)
    }, [])


    const handleCheckbox = () => {
        setAgbCheck(!agbCheck)
    }

    async function sendData(e) {
        e.preventDefault()

        if (!agbCheck) {
            setErrorMsg('Bestätige die AGBs!')
            return
        } else if (!allFilled()) {
            setErrorMsg('Bitte alle Felder ausfüllen!')
            return
        } else if (!ValidateEmail(mail)) {
            setErrorMsg('Bitte eine gültige E-Mail angeben!')
            return
        } else if (!ValidatePhoneNumber(tel)) {
            setErrorMsg('Bitte eine gültige Telefonnummer eingeben!')
            return
        } else if (!gebValid()) {
            setErrorMsg('Bitte ein gültiges Datum eingeben!')
            return
        } else {
            setErrorMsg('')
        } // clear if all fine


        // create customer object
        const customer = {
            name: name,
            telephone: FormatPhoneNumber(tel),
            email: mail,
            birthday: gebDate,
        }
        setLoadingStyleTag(' loading ')
        // create reservation object from service
        const reservation = await createReservation(event, customer, navigate)
        setLoadingStyleTag('')
        navigate('/checkout', {state: {reservation: reservation}})

    }


    const allFilled = () =>
        name !== '' && mail !== '' && tel !== '' && gebDate !== ''

    const gebValid = () => !isNaN(Date.parse(gebDate))

    function getCustomer() {
        if (!location.state || !location.state.reservation || location.state.reservation.event?.id !== event._id)
            return undefined;

        return location.state.reservation?.customer
    }

    const canBook = () => event.empty_slots > 0

    return (
        <div className='mb-32 card lg:card-side shadow-xl w-[95%] sm:w-3/4 h-3/4 max-h-full'>
            <figure className='w-auto lg:w-128 h-auto relative'>
                <div className='bg-black/50 w-full lg:w-128 h-full absolute rounded-l-2xl'/>
                <img
                    src={Img10}
                    alt='Album'
                    className='h-auto w-full lg:w-none lg:h-full'
                />
                <p className={`absolute top-8 font-semibold ${canBook() ? "text-xl": "text-3xl"}`}>
                    {canBook() ? `${event.booked_tickets} / ${event.availiable_slots} Teilnehmer` : 'Ausgebucht'}
                </p>
                <p className={'absolute top-14 text-lg font-semibold ' + (event.empty_slots === 0 ? 'hidden' : '')}>
                    {`noch ${event.empty_slots} ${event.empty_slots === 1 ? "Platz" :"Plätze"} frei!`}
                </p>
            </figure>
            <div className='card-body'>
                <form autoComplete='on' onSubmit={sendData}>
                    <h1 className='text-3xl font-bold'>{event.title}</h1>
                    <h2 className='text-lg underline leading-3 pt-2'>Thema</h2>
                    <p>{event.description}</p>
                    <h2 className='text-lg underline leading-3 pt-2'>Location</h2>
                    <p>{event.location}</p>
                    <h2 className='text-lg underline leading-3 pt-2'>Zeitrahmen</h2>
                    <p>{event.pretty_time}</p>
                    <h2 className='text-lg underline leading-3 pt-2'>Teilnahmegebühr</h2>
                    <p>{`${event.price}€`}</p>
                    <h2 className='text-lg underline leading-3 pt-2'>Anmeldung</h2>
                    <div className='form-control w-full lg:max-w-lg'>
                        <label className='label'>
                        <span className='label-text text-base-100'>
                            Vor- und Nachname
                        </span>
                        </label>
                        <input
                            disabled={!canBook()}
                            defaultValue={getCustomer()?.name ?? ""}
                            id='name'
                            type='text'
                            onChange={(text) => setName(text.target.value)}
                            placeholder='Max Mustermann'
                            className='input input-bordered w-full lg:max-w-lg text-black disabled:border-secondary disabled:bg-base-100/20 disabled:opacity-50'
                        />
                    </div>
                    <div className='form-control w-full lg:max-w-lg'>
                        <label className='label'>
                            <span className='label-text text-base-100'>E-Mail</span>
                        </label>
                        <input
                            disabled={!canBook()}
                            defaultValue={getCustomer()?.email ?? ""}
                            type='text'
                            onChange={(text) => setMail(text.target.value)}
                            placeholder='example@mail.com'
                            className='input input-bordered w-full lg:max-w-lg text-black disabled:border-secondary disabled:bg-base-100/20 disabled:opacity-50'
                        />
                    </div>
                    <div className='form-control w-full lg:max-w-lg'>
                        <label className='label'>
                        <span className='label-text text-base-100'>
                            Telefonnummer
                        </span>
                        </label>
                        <input
                            disabled={!canBook()}
                            defaultValue={getCustomer()?.telephone ?? ""}
                            type='text'
                            onChange={(text) => setTel(text.target.value)}
                            placeholder='+49 123 4567890'
                            className='input input-bordered w-full lg:max-w-lg text-black disabled:border-secondary disabled:bg-base-100/20 disabled:opacity-50'
                        />
                    </div>
                    <div className='form-control w-full lg:max-w-lg'>
                        <label className='label'>
                        <span className='label-text text-base-100'>
                            Geburtsdatum
                        </span>
                        </label>
                        <input
                            disabled={!canBook()}
                            defaultValue={getCustomer()?.birthday ?? ""}
                            type='date'
                            onChange={(text) => setGebDate(text.target.value)}
                            placeholder='01.01.2000'
                            className='input input-bordered w-full lg:max-w-lg text-black disabled:border-secondary disabled:bg-base-100/20 disabled:opacity-50'
                        />
                    </div>
                    <div className='text-start text-md text-error'>{errorMsg}</div>
                    <div className='flex justify-between pt-4 lg:max-w-lg'>
                        <label className='flex items-center h-full'>
                            <input disabled={!canBook()} type='checkbox' className='checkbox checkbox-primary  disabled:border-secondary disabled:bg-base-100/20 disabled:opacity-50' onChange={handleCheckbox}/>
                            <span className='pl-2 text-base-100 text-sm'>
                            Ich habe die{' '}
                                <a href='/agbs' className='underline'>
                                AGBs
                            </a>{' '}
                                gelesen und akzeptiert.
                        </span>
                        </label>
                        <button
                            disabled={!canBook()}
                            type='submit'
                            className={`${canBook()?"":"hidden"} btn h-14 w-32 text-lg text-blue-400 btn-outline ${agbCheck ? '' :
                             'btn-disabled disabled:border-secondary disabled:bg-base-200 disabled:opacity-50'} ${loadingStyleTag}`}>
                            {loadingStyleTag === '' ? 'Buchen' : ''}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
