import {parsePhoneNumber} from 'libphonenumber-js'

export function ValidateEmail(mail) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase())
}

export function ValidatePhoneNumber(number) {
    try {
        return parsePhoneNumber(String(number), 'DE').isValid()
    } catch (e) {
        return false
    }
}

export function FormatPhoneNumber(number) {
    return parsePhoneNumber(String(number), 'DE').formatInternational()
}

export function GetUriFromPhoneNumber(number) {
    if (!ValidatePhoneNumber(number)) return ""
    return parsePhoneNumber(String(number), 'DE').getURI()
}
