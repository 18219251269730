import GridPicV from "./pictureV";
import Img02 from '../../res/02k.jpg';
import Img03 from '../../res/03.jpg';
import Img04 from '../../res/04.jpg';
import Img05 from '../../res/05.jpeg';
import Img06 from '../../res/06.jpeg';
import Img07 from '../../res/07k.jpg';
import Img08 from '../../res/08k.jpg';
import Img09 from '../../res/09.jpg';
import Img10 from '../../res/10.jpg';
import Img11 from '../../res/11.jpg';
import Img12 from '../../res/12.jpg';
import Img13 from '../../res/13.jpg';
import Img14 from '../../res/14.jpg';
import Img15 from '../../res/15.jpg';
import GridPicH from "./pictureH";

export default function Gallery() {
    return(
        <section className="min-h-screen w-screen p-2 sm:p-4 max-w-screen-lg inline-grid gap-4 md:gap-6 xl:gap-9 py-24">
            <GridPicV text="TitatTest" bgimg={Img02} classes="md:justify-end" />
            <GridPicV text="TestTest" bgimg={Img03} classes="md:col-start-2 md:col-end-3 xl:justify-center" />
            <GridPicV text="Test" bgimg={Img04} classes="md:justify-end xl:col-start-3 xl:col-end-4 xl:justify-start" />
            <GridPicV text="Test" bgimg={Img05} classes="xl:justify-end" />
            <GridPicV text="Test" bgimg={Img06} classes="md:justify-end xl:justify-center" />
            <GridPicV text="Test" bgimg={Img07} classes="xl:justify-start" />
            <GridPicV text="Test" bgimg={Img08} classes="md:justify-end" />
            <GridPicV text="Test" bgimg={Img15} classes="xl:justify-center" />
            <GridPicV text="Test" bgimg={Img10} classes="md:justify-end xl:justify-start" />
            <GridPicV text="Test" bgimg={Img11} classes="xl:justify-end" />
            <GridPicV text="Test" bgimg={Img12} classes="md:justify-end xl:justify-center" />
            <GridPicV text="Test" bgimg={Img13} classes="xl:justify-start" />
            <GridPicH text="Test" bgimg={Img09} classes="md:justify-end lg:justify-start lg:col-start-1 lg:col-end-3 lg:row-start-5 lg:row-end-6" />
            <GridPicH text="Test" bgimg={Img14} classes="lg:justify-end lg:col-start-2 lg:col-end-4 lg:row-start-5 lg:row-end-6" />
        </section>
    )
}