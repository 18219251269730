import HeadlineWidget from '../common/headline'
import Gallery from './gallery'

export default function GalleryRoute() {
    return (
        <section className="router-body">
            <HeadlineWidget text="Galerie"/>
            <Gallery/>
        </section>
    )
}
