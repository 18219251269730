

export default function Impressum() {
    return(
        <div className="text-lg w-full flex flex-col items-start p-16">
                <h3 className="text-4xl font-bold pb-4">Impressum</h3>
                <h4 className="text-2xl font-semibold">Angaben gemäß § 5 TMG</h4>
                <p className="pl-4">Sebastian Brendel</p>
                <p className="pl-4">Tänzer / Tanzlehrer / Choreograf</p>
                <p className="pl-4">Hohe Straße, 18</p>
                <p className="pl-4">04107 Leipzig</p>
                <h4 className="text-2xl font-semibold pt-2">Kontakt</h4>
                <p className="pl-4">Telefon: +49 162 9833595</p>
                <p className="pl-4">E-Mail: xyz@mail.de</p>
                <h4 className="text-2xl font-semibold pt-2">Redaktionell verantwortlich</h4>
                <p>Sebastian Brendel</p>

                <h4 className="text-2xl font-semibold pt-2">EU-Streitschlichtung</h4>
                <p className="pl-4">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.</p>
                <p className="pl-4"> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>


                <h4 className="text-2xl font-semibold pt-2">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h4>
                <p className="pl-4">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. </p>
                <p className="pl-4 underline">Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">E-Recht</a></p>
          
        </div>
    )
}