import Img10 from '../../res/10s.jpg'
import PaymentForm from './paypal_input_form'
import { useEffect, useState } from 'react'
import { generateToken } from '../../services/paypal.service'
import { useNavigate } from 'react-router-dom'
import { loadReservation, removeReservationCookies } from '../../services/event.service'
import { prettifyDateWithoutTimeString } from '../../utils/dates'
import { currencyFormatter } from '../../utils/currency'
import { Card } from 'react-daisyui'


export default function CheckoutPage() {
    const navigate = useNavigate()
    const [token, setToken] = useState()
    const [reservation, setReservation] = useState()
    const [paymentSuccess, setPaymentSuccess] = useState(false)

    useEffect(() => {
        if (paymentSuccess) return
        generateToken(navigate).then((token) => {
            setToken(token)
        })

        loadReservation(navigate).then((reservation) => {
            if (reservation.code && reservation.code !== 200) {
                navigate(`/kurse/${reservation.code}`, { state: paymentSuccess ? undefined : { reservation: reservation } })
                removeReservationCookies()
            } else {
                setReservation(reservation)
            }
        })

    }, [navigate])

    const cancelAndNavigate = () => {
        removeReservationCookies()
        navigate('/kurse', { state: paymentSuccess ? undefined : { reservation: reservation } })
    }

    const confirmPayment = () => {
        setPaymentSuccess(true)
    }

    const loaded = () => {
        return token !== undefined && reservation !== undefined
    }


    return (
        loaded() ?
            <section
                className='h-full w-full flex flex-col flex-grow pt-8 lg:pt-0'>
                <div className='flex w-full pl-16 py-4'>
                    <button className='btn inline-flex items-center btn-outline btn-secondary '
                            onClick={cancelAndNavigate}>
                        <svg
                            viewBox='0 0 1024 1024'
                            fill='gray'
                            height='1em'
                            width='1em'
                            className='mr-1'
                        >
                            <path
                                d='M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z' />
                        </svg>
                        {paymentSuccess ? 'Zurück zur Startseite' : 'Zurück zur Kursauswahl'}
                    </button>
                </div>
                <div className={'flex flex-col lg:flex-row pb-16 lg:divide-x '}>

                    <div className='flex flex-row gap-16 lg:w-1/2 lg:p-8 xl:justify-end'>
                        <img
                            src={Img10}
                            alt='Album'
                            className='hidden xl:flex object-fill h-96 rounded-md'
                        />
                        <div className='flex flex-col p-2 '>
                            <h1 className='text-4xl xl:text-5xl text-left pb-2'>Teilnehmerinfo</h1>
                            <div
                                className='text-2xl xl:text-xl text-left pl-8 break-words mt-8'>{reservation.customer.name}</div>
                            <div
                                className='text-2xl xl:text-xl text-left pl-8 break-words'>{prettifyDateWithoutTimeString(reservation.customer.birthday)}</div>
                            <div
                                className='text-2xl xl:text-xl text-left pl-8 break-words'>{reservation.customer.email}</div>
                            <div
                                className='text-2xl xl:text-xl text-left pl-8 break-words'>{reservation.customer.telephone}</div>
                        </div>
                    </div>

                    <div className='flex flex-col grow p-2 lg:p-10 lg:w-1/2 '>
                        <div className='xl:max-w-2xl'>
                            <h1 className='text-4xl xl:text-5xl text-left pb-2 pt-16 lg:pt-0'>Bestellübersicht</h1>
                            <div className='flex flex-row justify-between mt-4'>
                                <div className='flex flex-row text-start '>
                                    <p className='text-2xl w-12'>1x</p>
                                    <p className='text-2xl'>{reservation.event.title}</p>
                                </div>
                                <p className='text-2xl w-28 text-end'>{currencyFormatter.format(reservation.price)}</p>
                            </div>
                            <div className='flex flex-row justify-between text-start '>
                                <div className='flex flex-row'>
                                    <p className='w-12' />
                                    <p className='text-l'>mit Sebastian Brendel in Leipzig</p>
                                </div>
                                <p className='text-2xl w-28 text-end' />
                            </div>
                            <div className='flex flex-row justify-between mt-8'>
                                <div className='flex flex-row'>
                                    <p className='text-2xl w-12' />
                                    <p className='text-2xl'>Versand</p>
                                </div>
                                <p className='text-2xl w-28 text-end'>kostenlos</p>
                            </div>
                            <div className='border border-b-gray-400 w-full px-4 my-4' />
                            <div className='flex flex-row justify-between'>
                                <p className='text-2xl'>Gesamtsumme</p>
                                <p className='text-2xl'>{currencyFormatter.format(reservation.price)}</p>
                            </div>
                            <div className='flex flex-col justify-center w-full h-fit p-8'>
                                <Card className={`bg-secondary ${paymentSuccess ? 'hidden' : ''}`}>
                                    <Card.Body className={'flex flex-row justify-center pb-4'}>
                                        <PaymentForm client_token={token} reservation={reservation}
                                                     on_success={confirmPayment} />
                                    </Card.Body>
                                </Card>
                                <Card className={`bg-accent/80 ${paymentSuccess ? '' : 'hidden'}`}>
                                    <Card.Title className={'flex flex-col justify-center pt-4'}>
                                        <div className={''}>Vielen Dank!</div>
                                    </Card.Title>
                                    <Card.Body>
                                        <p>Dein Kauf war erfolgreich! Du erhälst in Kürze eine
                                            Bestätigung an {reservation.customer.email}. <br /> Ich freue mich auf dich!
                                        </p>
                                        <p>Dein Sebastian Brendel</p>
                                        <p className={'text-secondary text-s'}>Transaktions-ID: {reservation.id}</p>
                                    </Card.Body>
                                </Card>

                            </div>
                        </div>
                    </div>
                </div>
            </section> : <section className='bg-base-200 text-secondary-content h-full w-full flex-grow' />
    )


}
