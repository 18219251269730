import Img01 from '../../res/01k.jpg'
import Img16 from '../../res/16.jpeg'
import ShowReel from '../../res/showreel.mp4'
import logo from '../../res/SB_LOGO_weiss.png'
import Contact from './contact'

export default function Homepage() {
    const bgstyle = { backgroundImage: `url(${Img16})`, backgroundSize: 'cover' }

    return (
        <section className='flex flex-col items-center'>



            <section className='w-screen min-h-screen flex flex-col justify-center items-center relative' style={bgstyle}>
                <div className='absolute bg-black w-full h-full opacity-70'/>
                <img src={logo} alt='Logo' className='h-48 w-auto z-40 ' />
                <h1 className='text-6xl text-center md:text-7xl lg:text-8xl font-extrabold italic z-40 text-white'>Sebastian
                    Brendel</h1>
                <h2 className='mt-2 text-2xl md:text-4xl lg:text-6xl font-bold italic z-40 text-white'>Tänzer
                    - Tanzlehrer - Choreograf</h2>
            </section>
        
        
        
        
        
            <section className='min-h-screen w-full overflow-hidden p-4 max-w-screen-2xl'>
                <div className='grid items-center justify-center lg:grid-cols-5 grid-rows-2'>
                    <div
                        className='flex h-128 justify-center lg:justify-end items-center lg:pr-10 col-start-1 col-end-2 md:col-end-3 row-start-1 row-end-2 sm:row-end-3 lg:row-end-2'>
                        <img src={Img01} alt='portrait'
                             className='h-112 w-80 border-6 border-black border-8 rounded-md' />
                    </div>
                    <div
                        className='flex flex-col lg:h-128 md:justify-center md:items-center lg:items-start px-2 sm:px-12 md:px-24 lg:pl-10 lg:pr-32 col-start-1 lg:col-start-3 col-end-2 md:col-end-3 lg:col-end-6 row-start-2 sm:row-start-3 lg:row-start-1 row-end-3 sm:row-end-4 lg:row-end-2'>
                        <h2 className='text-center lg:text-start w-full text-4xl font-bold'>Hey du...</h2> <br />
                        <p className='text-center lg:text-start text-xl font-semibold md:max-w-2xl'>
                            und herzlich Willkommen auf meiner Website. Ich bin Sebastian Brendel, geboren am
                            27.01.1995, ursprünglich aus Erfurt, nun ansässig in Leipzig und ich freue mich sehr über
                            deinen Besuch. <br />
                            Hier findest du all meine Bilder, Videos, Erfahrungen, Erlebnisse und Highlights aus meinem
                            tänzerischen Leben. Du hast Lust an einem meiner Kurse teilzunehmen: dann buch dir einfach
                            über den Kursreiter deinen Platz.
                            Oder du willst mich für einen Workshop, Auftritt oder Auftrag buchen. Dann schreibe mir gern
                            über das Kontaktformular weiter unten.
                            Vielleicht sehen wir uns schon bald, ich würde mich freuen. <br /><br />
                            Love & Peace <br />
                            Sebastian</p>
                    </div>
                    <div
                        className='flex items-center justify-center h-64 sm:h-72 md:h-80 lg:h-112 w-full sm:mt-32 lg:mx-0 col-start-1 col-end-2 md:col-end-3 lg:col-end-6 row-start-3 sm:row-start-4 lg:row-start-2 row-end-4 sm:row-end-5 lg:row-end-3'>
                        {/* <VideoPlayer /> */}
                        <div className='rounded-2xl h-full w-fit'>
                            <video src={ShowReel} autoPlay loop muted className='h-full w-full rounded-xl' />
                        </div>
                    </div>
                </div>
            </section>
            <Contact />
        </section>
    )
}
