import { BASE } from '../App'
import { AUTH_TOKEN_EXPIRY_NAME, AUTH_TOKEN_NAME, RESERVATION_TOKEN_NAME } from '../utils/constants'


export function getHeader(useDefaults = false) {
    let header = { 'Content-Type': 'application/json', }
    const authToken = window.localStorage.getItem(AUTH_TOKEN_NAME)
    const reservationToken = window.localStorage.getItem(RESERVATION_TOKEN_NAME)
    if (!useDefaults && authToken)
        header = { ...header, 'Authorization': 'bearer ' + (authToken), }
    if (!useDefaults && reservationToken)
        header = { ...header, 'X-Reservation': reservationToken, }

    return header
}

export async function getAuthState() {
    const token = window.localStorage.getItem(AUTH_TOKEN_NAME)
    const expiry = window.localStorage.getItem(AUTH_TOKEN_EXPIRY_NAME)
    if (token == null || expiry == null) return 401
    if (!token || new Date(expiry) < new Date()) {
        // token expired
        return 401
    }
    const endpoint = '/auth/state'
    const res = await fetch(
        BASE + endpoint, {
            headers: getHeader(),
        },
    )
    return (await res.json()).code
}


export function logout(navigate) {
    window.localStorage.removeItem(AUTH_TOKEN_NAME)
    window.localStorage.removeItem(AUTH_TOKEN_EXPIRY_NAME)
    navigate('/login')
}
