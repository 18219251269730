import TextInputField from './textinputfield'
import { sendContactMail } from '../../services/contact.service'
import { useState } from 'react'


export default function Contact() {
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [textColor, setTextColor] = useState('success')
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            message: e.target.message.value,
            subject: e.target.subject.value,
        }
        await sendContactMail(formData).then((e) => {
            if (e.code === 200) {
                setErrorMsg('Nachricht erfolgreich versendet!')
                setTextColor('success')
                setSent(true)
            } else {
                setErrorMsg(e.displayMessage)
                setTextColor('error')
            }
        })
        setLoading(false)
    }

    return (
        <div className='flex flex-col items-center' id='contact'>
            <h2 className='text-center text-5xl font-bold pt-32'>Kontaktiere Mich!</h2>
            <div className='flex flex-col md:flex-row mb-24'>
                <div className='p-4 md:pl-16'>
                    <form onSubmit={handleSubmit} autoComplete={'on'}>
                        <TextInputField width='max-w-xl' text='Vor- & Nachname' placeholder='Max Mustermann' id='name'
                                        disabled={sent} />
                        <div className='flex flex-col sm:flex-row justify-between max-w-xl'>
                            <TextInputField width='w-72 sm:w-64' text='Telefonnummer' placeholder='+01 234 567890'
                                            id={'phone'} disabled={sent} />
                            <TextInputField type={'email'} width='w-72' margin='sm:ml-8' text='E-Mail' id={'email'}
                                            placeholder='maxmustermann@example.com' disabled={sent} />
                        </div>
                        <TextInputField width='max-w-xl' text='Thema' placeholder='Der Auftrag' id={'subject'}
                                        disabled={sent} />
                        <div className='form-control w-full max-w-3xl'>
                            <label className='label pb-0'>
                                <span className='label-text text-lg italic font-semibold text-primary'>Nachricht</span>
                            </label>
                            <textarea disabled={sent}
                                      className={'textarea bg-base-200 textarea-bordered max-w-xl h-32 text-md ' +
                                          ' border-secondary disabled:border-secondary disabled:bg-base-200' +
                                          ' disabled:opacity-50'}
                                      placeholder='Hey, ich möchte dich für folgenden Auftritt engagieren...'
                                      name={'message'} />
                        </div>
                        <div className={`text-center w-full text-md text-${textColor}`}>{errorMsg}</div>
                        <button
                            className={`btn btn-primary btn-outline mt-4 text-lg h-16 ${loading ? 'loading' : ''} ${sent ? 'btn-disabled' : ''}`}
                            type={'submit'}>
                            {loading ? '' : (sent ? 'Gesendet' : 'Senden')}
                            <svg className='h-16 ml-2' fill='none' stroke='currentColor' strokeLinecap='round'
                                 strokeLinejoin='round' strokeWidth={2} viewBox='0 0 24 24' height='1.5rem'
                                 width='1.5rem'>
                                <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z' />
                                <path d='M22 6l-10 7L2 6' />
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
