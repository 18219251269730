

export default function AGB() {
    return(
        <div className="text-lg w-full flex flex-col items-start p-16 text-start">
            <h3 className="text-4xl font-bold ">Allgemeine Geschäftsbedingungen für Kursbuchungen</h3>
            <p>Training with Sebastian Brendel</p>
            <h4 className="text-2xl font-semibold pt-2">§1 Geltungsbereich</h4>
            <p className="pl-4">Die allgemeinen Geschäftsbedingungen gelten für alle Kurse, welche auf dieser Website unter dem Namen „Training with Sebastian Brendel“ angeboten werden.</p>
            <h4 className="text-2xl font-semibold pt-2">§2 Vertragsabschluss und -dauer</h4>
            <p className="pl-4">Mit Bestätigung der AGB ́s und der Buchung des Kurses kommt es zum Vertragsabschluss zwischen Sebastian Brendel und dem Vertragspartner. Die Vertragslaufzeit beschränkt sich auf die Zeit des gebuchten Kurses (also in der Regel 90 Minuten). Ein Kurs gilt erst als „gebucht“, wenn die Zahlung bei Sebastian Brendel eingegangen ist.</p>
            <h4 className="text-2xl font-semibold pt-2">§3 Vertragsgegenstand</h4>
            <p className="pl-4">Der Vertragspartner verpflichtet sich zur vollständigen Zahlung der Kursgebühr und ist im Gegenzug zum Besuch des gebuchten Kurses berechtigt. Workshops und Privatstunden sind nicht Vertragsgegenstand.</p>
            <h4 className="text-2xl font-semibold pt-2">§4 Zahlungsbedingungen</h4>
            <p className="pl-4">Die Kursgebühr ist bei Buchung des Kurses zu zahlen. Diese belaufen sich auf 15 € für 90 Minuten Kursdauer. (Änderungen und Anpassung der Gebühren behält sich Sebastian Brendel vor).</p>
            <h4 className="text-2xl font-semibold pt-2">§5 Krankheitsfall, Versäumnis des gebuchten Kurses</h4>
            <p className="pl-4">Im Krankheitsfall besteht kein Recht auf Rückerstattung des Kursbetrages. Sollte es zum Ausfall des Kurses durch Krankheit seitens Sebastian Brendel kommen, wird die gezahlte Gebühr zurückerstattet.</p>
            <h4 className="text-2xl font-semibold pt-2">§6 Teilnahmebedingungen</h4>
            <p className="pl-4">Sofern sich der Teilnehmer unbillig verhält (z.B. durch Trunkheit, Beleidigungen etc. auffällt) kann Sebastian Brendel es ohne Anspruch auf Rückerstattung von Kursgebühren vom weiteren Unterricht bzw. Angebot ausschließen. </p>
            <p className="pl-4">Die Teilnehmerzahl eines jeweiligen Kurses ist aufgrund der Größe des Raumes begrenzt und liegt im Ermessen von Sebastian Brendel.</p>
            <h4 className="text-2xl font-semibold pt-2">§7 Haftung</h4>
            <p className="pl-4">Der gesamte Aufenthalt in den Kursräumen geschieht auf eigene Gefahr des Teilnehmers. Für Personen- oder Sachschäden, die nicht von Sebastian Brendel verursacht werden, ist jede Haftung ausgeschlossen. Für die Garderobe des Teilnehmers bzw. dessen anderweitigen (Wert)-Gegenstände etc. wird keine Haftung übernommen.</p>
            <h4 className="text-2xl font-semibold pt-2">§8 Verwendungen von Inhalten, Foto- und Filmaufnahmen</h4>
            <p className="pl-4">Während der Kurse (Training with Sebastian Brendel) werden gelegentlich Foto- und Filmaufnahmen getätigt. Der Teilnehmer willigt ein, dass Foto- und Filmaufnahmen, auf denen das Bildnis des Teilnehmers erscheint, zu werblichen Zwecken von Sebastian Brendel verbreitet und öffentlich zur Schau gestellt werden dürfen, insbesondere auch im Internet auf der Homepage von Sebastian Brendel, im Rahmen von Social-Media Marketing, auf Flyern und vergleichbaren Werbeträgern.</p>
            <p className="pl-4">Sollte der Teilnehmer mit der Verwendung seines Bildnisses im Nachhinein nicht mehr einverstanden sein und kein Widerrufs- oder Anfechtungsrecht haben, wird Sebastian Brendel die Verwendung der entsprechenden Bilder aus Kulanz unterlassen, außer wenn für die Verwendung der Bilder nicht unerhebliche Aufwendungen getätigt wurden, die infolge des Unterlassens vergeblich würden. </p>
            <h4 className="text-2xl font-semibold pt-2">§9 Schlussbestimmungen</h4>
            <p className="pl-4">Für diesen Vertragsabschluss und dessen Durchführung gilt ausschließlich das Recht der Bundesrepublik Deutschland. Sollte eine Bestimmung dieser Vereinbarung rechtsunwirksam sein oder werden, so wird die Gültigkeit der übrigen Bestimmungen dadurch nicht berührt. Die unwirksame Klausel gilt als ersetzt durch eine Bestimmung, die dem wirtschaftlichen Zweck der unwirksamen Klausel möglichst nahe kommt. Änderungen oder Ergänzungen des Vertrages bedürfen zu ihrer Wirksamkeit der Schriftform.</p>
            <button className="btn btn-outline btn-primary mt-8 h-14 w-32 text-lg text-blue-400" onClick={() => window.print(document)}>Drucken</button>
        </div>
    )
}