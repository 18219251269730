import Impressum from "./impressum";


export default function Impressum_Route() {
    return (
        <section className="router-body">
            <Impressum/>
        </section>
    )
}
