import Vita from './vita'
import HeadlineWidget from '../common/headline'

export default function VitaRoute() {
    return (
        <section className="router-body">
            <HeadlineWidget text="Vita"/>
            <Vita/>
        </section>
    )
}
