import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InterfaceDetailTableRow from './interfaceDetailTableRow'
import { generateOTP } from '../../../services/event.service'
import DeleteConfirmationModal from '../../common/admin/deleteConfirmationModal'


export default function InterfaceDetail({ event }) {
    const [modalOpen, setModalOpen] = useState(false)
    const navigate = useNavigate()


    const openDeleteConfirmation = async () => {
        await generateOTP(event, navigate)
        setModalOpen(true)
    }
    const closeDeleteConfirmation = () => {
        setModalOpen(false)
    }

    const showDisable = () => {
        if (event === undefined) return true
        return event.disabled
    }

    const getInfoText = () => {
        if (event === undefined) return 'Lade Event...'
        if (event.disabled) {
            return 'Dieser Event wurde deaktiviert. '
        }
        return 'Dieser Event ist aktiv.'
    }
    const getBookingInfoText = () => {
        if (event === undefined) return undefined
        if (event.disabled) {
            const refund_complete_count = event.bookings.filter((b) => b.refund_complete).length
            return `${refund_complete_count}/${event.bookings.length} Buchungen wurden erfolgreich erstattet.`
        }
        return `Es sind ${event.bookings.length} Buchungen vorhanden.`
    }


    return (

        <div className='flex flex-col overflow-x-auto px-8 py-16 mb-16 w-full lg:w-2/3'>
            <DeleteConfirmationModal event={event} close={closeDeleteConfirmation} showDisbale={showDisable}
                                     isOpen={modalOpen} />
            <div className={'flex flex-row justify-between'}>
                <button className='btn inline-flex items-center btn-outline btn-secondary '
                        onClick={() => navigate(-1)}>
                    <svg
                        viewBox='0 0 1024 1024'
                        fill='gray'
                        height='1em'
                        width='1em'
                        className='mr-1'
                    >
                        <path
                            d='M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z' />
                    </svg>
                    Zurück
                </button>
                <button className={`btn btn-error btn-outline w-48 ${showDisable() ? 'hidden' : ''}`}
                        onClick={openDeleteConfirmation}>Absagen
                </button>
            </div>
            <div className={'text-center w-full text-sm md:text-lg my-2'}>
                <p className=''>{getInfoText()}</p>
                <p className=''>{getBookingInfoText()}</p>
            </div>
            <table
                className={`${event?.bookings.length === 0 ? 'hidden' : ''} table w-full [&_*]:bg-black/30 table-zebra mt-8`}>
                {/* <!-- head --> */}
                <thead>
                <tr>
                    <th className='' />
                    <th className='text-start md:text-lg'>Name</th>
                    <th className='text-start md:text-lg'>Kontaktdaten</th>
                    <th className='text-start md:text-lg'>Transaktions-ID</th>
                </tr>
                </thead>
                <tbody>
                {event?.bookings.map((booking, index) => {
                    return <InterfaceDetailTableRow key={index}
                                                    index={index + 1}
                                                    showStatusIcon={event?.disabled}
                                                    refundComplete={booking?.refund_complete}
                                                    customer={booking?.customer}
                                                    transactionId={booking?.transactionId}
                    />
                })}
                <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                </tr>

                </tbody>
            </table>
            <button className='btn btn-success w-48 self-end mt-8 btn-outline'
                    onClick={() => window.print(document)}>Liste Drucken
            </button>
        </div>
    )
}
