import {Alert, Button} from "react-daisyui";

export function Notification({visible, error, close}) {
    if (error === undefined) {
        return null
    }
    return (

        <Alert className={`${visible ? "" : "hidden"} z-10 fixed bottom-10 right-8  md:w-max w-10/12`}
               icon={
                   <svg

                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 24 24"
                       className="w-8 h-8 mx-2 stroke-current">
                       <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth="2"
                           d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                   </svg>
               }
               status={error.color}
        >
            <div className="w-full flex-row justify-between gap-2">
                <h3 className="text-lg font-bold">{error.title}</h3>
                <h4>{error.details}</h4>
            </div>
            <Button className={"btn-error bg-transparent border-0 hover:bg-error-content/20"} onClick={close}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30"
                     height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="18" y1="6" x2="6" y2="18"/>
                    <line x1="6" y1="6" x2="18" y2="18"/>
                </svg>
            </Button>
        </Alert>

    )
}


export function createNotificationContentFromCode(code) {
    switch (code) {
        case "401":
            return {
                title: "Deine Reservierung ist leider abgelaufen.",
                details: "Du kannst jederzeit einen neuen Bestellvorgang starten.",
                color: "error"
            }
        case "410":
            return {
                title: "Für deine Veranstaltung wurde der letzte Platz gerade vergriffen.",
                details: "Jemand anderes war leider schneller. Vielleicht kannst du ja zu einem andere Termin.",
                color: "error"
            }
            break;
        case "400":
        case "404":
        case "500":
            return {
                title: "Bei der Bestellung ist ein Fehler aufgetreten.",
                details: "Bitte versuche es erneut.", color: "error"

            }
        case "200":

            return {
                title: "Deine Bestellung ist erfolgreich eingegangen.",
                details: "Ich freue mich auf deinen Besuch.",
                color: "success"
            }
        default:
            return undefined

    }


}
