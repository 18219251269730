import {Link} from 'react-daisyui'
import {useNavigate} from 'react-router-dom'
import logo from '../../res/SB_LOGO_weiss.png'

export default function Header() {
    let navigate = useNavigate()
    return (
        <div className="navbar bg-accent text-base-100">
            <div className="flex-1">
                <Link
                    className="hidden sm:flex text-xl font-medium"
                    onClick={() => navigate('/')}>
                    <img src={logo} alt="Logo" className="h-12 w-auto px-2"/>
                </Link>
            </div>
            <div className="flex-none">
                <ul className="menu menu-horizontal px-1">
                    <li>
                        <Link
                            className="text-xl font-medium"
                            onClick={() => navigate('/')}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="text-xl font-medium"
                            onClick={() => navigate('/vita')}>
                            Vita
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="text-xl font-medium"
                            onClick={() => navigate('/galerie')}>
                            Galerie
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="text-xl font-medium"
                            onClick={() => navigate('/kurse')}>
                            Kurse
                        </Link>
                    </li>
                    
                </ul>
            </div>
        </div>
    )
}
