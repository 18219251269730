import HeadlineWidget from '../../common/headline'
import Interface from './interface'

export default function InterfaceRoute() {
    return (
        <section className='router-body'>
            <HeadlineWidget text='Verwaltung'/>
            <Interface/>
        </section>
    )
}
