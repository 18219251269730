import {GetUriFromPhoneNumber} from "../../common/util/validation";
import {prettifyDateWithoutTimeString} from "../../../utils/dates";
import {Link} from "react-daisyui";

export default function InterfaceDetailTableRow({index, showStatusIcon, refundComplete, customer, transactionId}) {
    const birthday = prettifyDateWithoutTimeString(customer?.birthday)

    function statusField() {
        if (!showStatusIcon) return index.toString()
        if (refundComplete) {
            return <svg className={"bg-transparent"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#20c565"
                        fill="none" strokeLinecap="round" width='25'
                        height='25'
                        strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="9 11 12 14 20 6"/>
                <path d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"/>
            </svg>
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg"
                        width='25'
                        height='25' viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FC406C" fill="none"
                        strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9"/>
                <path d="M10 10l4 4m0 -4l-4 4"/>
            </svg>
        }
    }

    return (
        <tr>
            <td className="text-center">
                {statusField()}
            </td>
            <td className="text-start ">{customer?.name ?? ""}</td>
            <td className="text-start flex flex-col">
                <Link className={""} href={`mailto:${customer?.email}`}>{customer?.email}</Link>
                <Link href={GetUriFromPhoneNumber(customer?.telephone)}>{customer?.telephone}</Link>
                {birthday ?? ""}
            </td>
            <td className="text-start overflow-auto break-words text-xs lg:text-sm">{transactionId ?? ""}</td>
        </tr>
    )
}
