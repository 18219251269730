import {useState} from 'react'


export default function Login({onLogin}) {

    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    async function handleSubmit(event) {
        event.preventDefault()
        setErrorMsg(await onLogin(username, password))
    }

    return (
        <section className='flex flex-col items-center w-full h-full max-w-xs '>
            <h1 className='font-semibold pb-2 text-lg'>Hey Basti, bitte einloggen :)</h1>
            <form className='w-full' onSubmit={handleSubmit}>
                <div className=' flex flex-col'>
                    <label htmlFor='email'
                           className='self-start mb-2 text-sm font-medium text-gray-900 dark:text-white'>Benutzername</label>
                    <input type='email' onChange={(event) => setUsername(event.target.value)}
                           className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                           placeholder='email@company.com' required/>
                </div>
                <div className=' flex flex-col'>
                    <label htmlFor='email'
                           className='self-start mb-2 text-sm font-medium text-gray-900 dark:text-white'>Passwort</label>
                    <input type='password' onChange={(event) => setPassword(event.target.value)}
                           className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                           placeholder='•••••••••' required/>
                </div>
                <div className='text-start text-md text-error'>{errorMsg}</div>
                <button type='submit' className='btn btn-primary my-4 w-32'>Einloggen</button>

            </form>
        </section>
    )
}
